var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',{key:_vm.clue.color,staticClass:"clue pl-0",class:{
    selected: _vm.clue.ordinal == _vm.selectedClue.ordinal,
  },style:({
    background: !_vm.colorless ? _vm.clue.color : _vm.dark ? '#999999' : 'white',
    marginTop:
      _vm.clue.ordinal == 1 && _vm.$vuetify.breakpoint.smAndUp ? '0px' : '-2px',
  }),attrs:{"id":`clue-${_vm.clue.ordinal}`},on:{"click":function($event){return _vm.$store.commit('PUZZLE/SELECT', { r: _vm.np[0], c: _vm.np[1] })}}},[_c('v-list-item-content',{staticClass:"pa-0",staticStyle:{"flex-wrap":"nowrap"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.clue.ordinal)+" ")])]),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"7"}},[_c('v-list-item-title',{staticClass:"text-wrap no-ellipsis",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.clue.clue)+" ("+_vm._s(_vm.clue.reveal.length)+") ")])],1),(_vm.clue.revealed)?_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('v-list-item-title',{staticClass:"text-wrap no-ellipsis d-flex align-center justify-center",staticStyle:{"font-size":"1.2rem","font-weight":"700","min-height":"40px"},domProps:{"textContent":_vm._s(_vm.clue.reveal.toUpperCase())}})],1):_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-text-field',{key:_vm.clue.ordinal,staticClass:"scratchpad",class:{
          'v-input--is-focused':
            _vm.assignedFocus == 'scratchpad-' + _vm.clue.ordinal,
        },attrs:{"placeholder":"Jot ideas...","outlined":"","inputmode":"none","hide-details":"","color":"black","dense":"","tabindex":10000 + _vm.clue.ordinal},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('scratchpadFocused', _vm.clue.ordinal)},"focus":function($event){$event.stopPropagation();return _vm.$emit('scratchpadFocused', _vm.clue.ordinal)}},model:{value:(_vm.uppercaseScratch),callback:function ($$v) {_vm.uppercaseScratch=$$v},expression:"uppercaseScratch"}}):_c('v-text-field',{key:'focused-' + _vm.clue.ordinal,staticClass:"scratchpad",class:{
          'v-input--is-focused':
            _vm.assignedFocus == 'scratchpad-' + _vm.clue.ordinal,
        },attrs:{"value":_vm.scratches[_vm.clue.ordinal],"readonly":"","inputmode":"none","placeholder":"Jot ideas...","outlined":"","hide-details":"","color":"black","dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('scratchpadFocused', _vm.clue.ordinal)},"focus":function($event){$event.stopPropagation();return _vm.$emit('scratchpadFocused', _vm.clue.ordinal)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }