<template>
  <v-card>
    <v-card-title>Color your Grid</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-color-picker
            v-model="color"
            dot-size="25"
            mode="hexa"
            :swatches="swatches"
            show-swatches
            hide-sliders
          ></v-color-picker>
        </v-col>
        <v-col cols="4">
          <v-btn block @click="color = white">White</v-btn>
          <v-btn block @click="clearGrid">Clear Colors</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="4">
          <v-btn text @click="$store.commit('CREATOR/DECREMENT_STEP')">
            Back
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="4">
          <v-btn
            :color="$colors.blue"
            block
            class="white--text"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    color: {
      alpha: 1,
      hex: "#5897a9",
      hexa: "#5897a9FF",
    },
    white: {
      alpha: 1,
      hex: "#FFFFFF",
      hexa: "#FFFFFFFF",
      hsla: { h: 0, s: 0, l: 1, a: 1 },
      hsva: { h: 0, s: 0, v: 1, a: 1 },
      hue: 0,
      rgba: { r: 255, g: 255, b: 255, a: 1 },
    },
  }),
  computed: {
    swatches() {
      return [
        [this.$colors.blue],
        [this.$colors["light-blue"]],
        [this.$colors.pink],
        [this.$colors.red],
        [this.$colors.orange],
        [this.$colors.purple],
        [this.$colors.green],
        [this.$colors.yellow],
      ];
    },
  },
  methods: {
    clearGrid() {
      this.$store.commit("CREATOR/CLEAR_GRID");
    },
    submit() {
      this.$store.commit("CREATOR/INCREMENT_STEP");
    },
  },
  mounted() {
    this.$store.commit("CREATOR/NUMBER_PLACEMENTS_SET", {});
  },
  watch: {
    color: {
      handler() {
        this.$store.commit("CREATOR/COLOR_SET", this.color.hex);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
