import Vue from "vue";
import Vuex from "vuex";

// vuex modules
import CREATOR from "./modules/CREATOR";
import PUZZLE from "./modules/PUZZLE";
import USER from "./modules/USER";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    CREATOR,
    PUZZLE,
    USER,
  },
  state: {
    sb_user: null,
  },
  mutations: {
    USER_CLEAR(state) {
      state.sb_user = null;
    },
    USER_SET(state, user) {
      state.sb_user = user;
    },
  },
});

export default store;
