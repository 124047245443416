var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500","dark":_vm.dark,"persistent":!_vm.gameStarted},on:{"click:outside":_vm.close},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticClass:"pt-2 pb-1"},[_c('v-col',{staticClass:"d-flex align-center px-0",attrs:{"cols":"9"}},[_vm._v("How to Play")]),_c('v-col',{staticClass:"d-flex justify-end align-center px-0",attrs:{"cols":"3"}},[(_vm.user && !_vm.example)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$store.commit('USER/ACCOUNT_DIALOG_SET', true)}}},[(_vm.numMissedPuzzles)?_c('v-badge',{attrs:{"color":_vm.$colors.red,"content":_vm.numMissedPuzzles}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.account)+" ")])],1):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.account)+" ")])],1):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-1"},[_c('v-carousel',{staticStyle:{"border-radius":"10px"},attrs:{"height":"420","hide-delimiters":""},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},[(_vm.example && _vm.pendo && !('getSerializedMetadata' in _vm.pendo))?_c('v-carousel-item',{staticClass:"vci"},[_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"color":"black","tile":"","height":"420"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-size":"60px","text-align":"center"}},[_vm._v("‼️")])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"10","offset":"1"}},[_c('h1',{staticClass:"text-center white--text",staticStyle:{"line-height":"1"},style:({
                    'font-size': _vm.$vuetify.breakpoint.xsOnly ? '30px' : '48px',
                  })},[_vm._v(" Uh oh! ")])]),_c('v-col',{staticClass:"d-flex justify-center pb-0",attrs:{"cols":"8","offset":"2"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" We can't render the interactive tutorial. "),_c('br'),_vm._v(" Please disable any ad blockers. "),_c('br'),_c('br'),_vm._v(" Alternatively, you can read the instructions by clicking the right arrow. ")])]),_c('v-spacer')],1)],1)],1):(!_vm.$ark.active)?_c('v-carousel-item',{staticClass:"vci"},[_c('v-sheet',{staticClass:"d-flex align-center",attrs:{"color":_vm.$colors.blue,"tile":"","height":"420"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"10","offset":"1"}},[_c('h1',{staticClass:"text-center black--text",staticStyle:{"line-height":"1"},style:({
                    'font-size': _vm.$vuetify.breakpoint.xsOnly ? '30px' : '48px',
                  })},[_vm._v(" Did you know... ")])]),_c('v-col',{staticClass:"d-flex justify-center pb-0",attrs:{"cols":"8","offset":"2"}},[_c('ul',{staticClass:"black--text"},[_c('li',[_vm._v(" You can customize the interface in "),_c('a',{staticStyle:{"color":"black !important","font-weight":"bold","text-decoration":"underline"},on:{"click":function($event){return _vm.$store.commit('USER/SETTINGS_DIALOG_SET', true)}}},[_vm._v(" Settings ")])]),_c('li',[_vm._v(" Puzzle "),_c('strong',[_vm._v("difficulty increases")]),_vm._v(" from Monday through Saturday. ")]),(!_vm.$ark.active)?_c('li',[_vm._v(" Signed-in users can "),_c('strong',[_vm._v("play the previous week")]),_vm._v(" of puzzles. ")]):_vm._e()])]),(_vm.user)?[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center black--text"},[_vm._v(" Signed in as: "),_c('strong',[_vm._v(_vm._s(_vm.user.email))])])])]:_vm._e(),_c('v-spacer')],2)],1)],1):_vm._e(),_vm._l((_vm.slides),function(slide,i){return _c('v-carousel-item',{key:i,staticClass:"vci"},[_c('v-sheet',{staticStyle:{"margin-top":"12px"},attrs:{"color":slide.color,"tile":"","height":"420"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('v-col',{staticClass:"py-0 text-center justify-center align-center d-flex",style:({
                  height: '150px',
                  color: _vm.colorless ? 'white' : 'black',
                }),attrs:{"cols":"10"},domProps:{"innerHTML":_vm._s(slide.text)}}),_c('v-spacer')],1),('image' in slide)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center py-0",attrs:{"cols":"12"}},[_c('v-img',{staticStyle:{"min-width":"250px","min-height":"250px"},attrs:{"src":slide.image,"max-height":"250","max-width":"250"}})],1),_c('v-spacer')],1):_vm._e()],1)],1)}),_c('v-carousel-item',{staticClass:"vci"},[_c('v-sheet',{staticStyle:{"margin-top":"12px"},attrs:{"color":_vm.$colors.red,"tile":"","height":"420"}},[_c('v-row',{staticClass:"pt-6"},[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center pt-0",attrs:{"cols":"12"}},[_c('a',{attrs:{"href":"/help-video","target":"_blank"}},[_c('v-img',{staticStyle:{"min-width":"180px","min-height":"180px"},attrs:{"src":require('@/assets/instructions/blockables-instructions-5.png'),"max-width":"180","max-height":"180"}})],1)]),_c('v-col',{staticClass:"d-flex justify-center pt-0",attrs:{"cols":"12"}},[(!_vm.example)?_c('router-link',{attrs:{"to":"/example"}},[_c('v-img',{staticStyle:{"min-width":"180px","min-height":"180px"},attrs:{"src":require('@/assets/instructions/blockables-instructions-6.png'),"max-width":"180","max-height":"180"}})],1):_vm._e()],1),_c('v-spacer')],1)],1)],1)],2),_c('p',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(_vm.carousel + 1)+"/"+_vm._s(_vm.presentSlides()))])],1),_c('v-divider'),_c('v-card-actions',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":_vm.dark ? 'white' : _vm.$colors.blue,"text":"","block":""},on:{"click":_vm.close}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }