export default {
  COLOR_COUNTS: state => override => {
    let counts = {};
    let loopable = override ? override : state.gridColors;
    for (let i = 0; i < loopable.length; i++) {
      let row = loopable[i];
      for (let j = 0; j < row.length; j++) {
        let color = row[j];
        if (color in counts) {
          counts[color]++;
        } else {
          counts[color] = 1;
        }
      }
    }
    return counts;
  },
};
