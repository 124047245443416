import Vue from "vue";

export default {
  // Step 1
  GRID_DATA_SET(state, payload) {
    state.grid = payload.grid;
    state.phrase = payload.phrase;
    state.direction = payload.direction;
    state.coilPattern = payload.coilPattern;
  },

  // Step 2
  COLOR_SET(state, color) {
    state.color = color;
  },

  // Step 3
  TARGETING_SET(state, targeting) {
    state.targeting = targeting;
  },
  NUMBER_PLACEMENT_SET(state, payload) {
    Vue.set(state.numberPlacements, state.targeting.ordinal, [
      payload.r,
      payload.c,
    ]);
  },
  FIRST_LETTER_SET(state, payload) {
    state.words[payload.ordinal] = payload.letter;
  },
  CLUES_SET(state, clues) {
    state.clues = clues;
  },
  WORDS_SET(state, words) {
    state.words = words;
  },
};
