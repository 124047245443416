<template>
  <v-btn
    v-if="activator == 'button'"
    :color="$colors.blue"
    dark
    block
    @click="$store.commit('USER/ACCOUNT_DIALOG_SET', true)"
  >
    Sign In
  </v-btn>
  <v-list-item
    v-else
    @click="$store.commit('USER/ACCOUNT_DIALOG_SET', true)"
    one-line
    block
  >
    <v-list-item-content>
      <v-list-item-title>
        <strong>Your stats are not saved!</strong>
        <br />
        <span style="text-decoration: underline">
          Log in to save your stats.
        </span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ["activator"],
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
  },
};
</script>

<style></style>
