<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-list-item
    :id="`clue-${clue.ordinal}`"
    :key="clue.color"
    class="clue pl-0"
    :class="{
      selected: clue.ordinal == selectedClue.ordinal,
    }"
    :style="{
      background: !colorless ? clue.color : dark ? '#999999' : 'white',
      marginTop:
        clue.ordinal == 1 && $vuetify.breakpoint.smAndUp ? '0px' : '-2px',
    }"
    @click="$store.commit('PUZZLE/SELECT', { r: np[0], c: np[1] })"
  >
    <v-list-item-content class="pa-0" style="flex-wrap: nowrap">
      <v-col cols="1">
        <strong>
          {{ clue.ordinal }}
        </strong>
      </v-col>
      <v-col cols="7" class="pa-1">
        <v-list-item-title
          class="text-wrap no-ellipsis"
          style="font-size: 0.9rem"
        >
          {{ clue.clue }} ({{ clue.reveal.length }})
        </v-list-item-title>
      </v-col>
      <v-col cols="4" class="px-0" v-if="clue.revealed">
        <v-list-item-title
          class="text-wrap no-ellipsis d-flex align-center justify-center"
          style="font-size: 1.2rem; font-weight: 700; min-height: 40px"
          v-text="clue.reveal.toUpperCase()"
        />
      </v-col>
      <v-col cols="4" class="px-0" v-else>
        <v-text-field
          v-if="$vuetify.breakpoint.smAndUp"
          :key="clue.ordinal"
          v-model="uppercaseScratch"
          class="scratchpad"
          :class="{
            'v-input--is-focused':
              assignedFocus == 'scratchpad-' + clue.ordinal,
          }"
          placeholder="Jot ideas..."
          outlined
          inputmode="none"
          hide-details
          color="black"
          dense
          :tabindex="10000 + clue.ordinal"
          @click.stop="$emit('scratchpadFocused', clue.ordinal)"
          @focus.stop="$emit('scratchpadFocused', clue.ordinal)"
        />
        <v-text-field
          v-else
          :key="'focused-' + clue.ordinal"
          class="scratchpad"
          :class="{
            'v-input--is-focused':
              assignedFocus == 'scratchpad-' + clue.ordinal,
          }"
          :value="scratches[clue.ordinal]"
          readonly
          inputmode="none"
          placeholder="Jot ideas..."
          outlined
          hide-details
          color="black"
          dense
          @click.stop="$emit('scratchpadFocused', clue.ordinal)"
          @focus.stop="$emit('scratchpadFocused', clue.ordinal)"
        />
      </v-col>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: ["assignedFocus", "clue", "scratches", "selectedClue"],
  computed: {
    colorless() {
      return this.$store.state.USER.settings.colorless;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    np() {
      return this.$store.state.PUZZLE.numberPlacements[this.clue.ordinal];
    },
    uppercaseScratch: {
      get() {
        return this.$store.state.PUZZLE.scratches[this.clue.ordinal];
      },
      set(v) {
        this.$store.commit("PUZZLE/SCRATCH_SET", {
          ordinal: this.clue.ordinal,
          text: v.toUpperCase(),
        });
      },
    },
  },
};
</script>

<style>
.clue {
  margin: auto 0;
  margin-top: -2px;
  border: black 2px solid;
}

.no-ellipsis {
  text-overflow: clip;
  overflow: visible;
}

.selected {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}
</style>
