export default {
  ARROW_CLASS_SET(state, ac) {
    state.arrowClass = ac;
  },
  ASSIGNED_FOCUS_SET(state, af) {
    state.assignedFocus = af;
  },
  CLUES_SET(state, c) {
    for (const key in state.clues) {
      delete state.clues[key];
      state.clues[key] = c[key];
      let ordinal = state.clues[key].ordinal;
      // for reactivity zzz
      delete state.scratches[ordinal];
      state.scratches[ordinal] = "";
    }
  },
  DIMENSION_SET(state, d) {
    state.dimension = d;
    state.checks = [
      ...Array.from(Array(state.dimension), () =>
        Array(state.dimension).fill(null)
      ),
    ];
    state.playerGrid = [
      ...Array.from(Array(state.dimension), () =>
        Array(state.dimension).fill(null)
      ),
    ];
  },
  DIRECTION_SET(state, d) {
    state.direction = d;
  },
  GRID_COLORS_SET(state, gc) {
    state.gridColors = gc;
  },
  METADATA_SET(state, dc) {
    state.metadata = {
      author: "author" in dc ? dc.author : { link: "", name: "" },
      category: dc.category,
      date: dc.date,
      editor: "editor" in dc ? dc.editor : "",
      puzzleId: dc.id,
      puzzleNumber: dc.ordinal,
    };
  },
  NUMBER_PLACEMENTS_SET(state, np) {
    state.numberPlacements = np;
  },
  PUZZLE_SET(state, p) {
    state.clues = p.clues;
    state.dimension = p.dimension;
    state.direction = p.direction;
    state.gridColors = JSON.parse(p.gridColors);
    state.winGrid = JSON.parse(p.grid);
    state.numberPlacements = p.numberPlacements;
    state.phrase = p.phrase;
    state.selected = {
      r: p.numberPlacements["1"][0],
      c: p.numberPlacements["1"][1],
    };
  },
  SCRATCH_SET(state, payload) {
    // for reactivity zzz
    delete state.scratches[payload.ordinal];
    state.scratches = {
      ...state.scratches,
      ...{ [payload.ordinal]: payload.text },
    };
  },
  SCRATCHES_SET(state, s) {
    for (const key in state.clues) {
      let ordinal = state.clues[key].ordinal;
      // for reactivity zzz
      delete state.scratches[ordinal];
      state.scratches[ordinal - 1] = s[key];
    }
  },
  WIN_GRID_SET(state, wg) {
    state.winGrid = wg;
  },
};
