<template>
  <v-container>
    <PuzzleConfirmer />
    <ArkadiumWinCondition v-if="$ark.active && !example" />
    <WinCondition v-else-if="!example" />
    <ExampleWinCondition v-else />
    <StickyClue />
    <Helpers />
    <v-row>
      <v-spacer />
      <Grid />
      <ClueList>
        <template v-if="!isProd" slot="additional-actions">
          <Tools />
        </template>
      </ClueList>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import ArkadiumWinCondition from "@/components/dialogs/ArkadiumWinCondition.vue";
import ExampleWinCondition from "@/components/example/ExampleWinCondition.vue";
import PuzzleConfirmer from "@/components/dialogs/PuzzleConfirmer.vue";
import WinCondition from "@/components/dialogs/WinCondition.vue";

import Tools from "@/components/superuser/Tools.vue";

import ClueList from "@/components/grid/ClueList.vue";
import Grid from "@/components/grid/Grid.vue";
import Helpers from "@/components/grid/Helpers.vue";
import StickyClue from "@/components/grid/StickyClue.vue";

export default {
  components: {
    ArkadiumWinCondition,
    ClueList,
    ExampleWinCondition,
    Grid,
    Helpers,
    PuzzleConfirmer,
    StickyClue,
    Tools,
    WinCondition,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    dimension() {
      return this.$store.state.PUZZLE.dimension;
    },
    example() {
      return this.$store.state.USER.example;
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
    firstSquare() {
      let np = this.$store.state.PUZZLE.numberPlacements;
      if (!np) return { r: 0, c: 0 };
      return {
        r: np["1"][0],
        c: np["1"][1],
      };
    },
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT === "prod";
    },
    playAgainCount() {
      return this.$store.state.USER.playAgainCount;
    },
    user() {
      return this.$store.state.sb_user;
    },
  },
  watch: {
    async date() {
      await this.initialize();
    },
    async playAgainCount() {
      await this.initialize();
    },
  },
  async mounted() {
    document.body.addEventListener("click", this.unlockAudio);
    document.body.addEventListener("touchstart", this.unlockAudio);

    await this.initialize();
    if (this.$route.hash.includes("error_description")) {
      this.$store.commit("USER/SNACKBAR_SET", {
        type: "bad",
        active: true,
        text: "ERROR: " + this.$route.hash,
      });
    }
    if (this.user) {
      this.$store.dispatch("USER/GET_STATS_FROM_DB", this.user);
      let { data, error } = await this.$db.from("user_mgmt").select();
      if (!data.length && !error) {
        this.$store.dispatch("USER/SIGN_OUT");
        this.$router.push("/");
      }
    }
    if (this.$ark.active) {
      this.$ark.api.initTestingEnvironment();
    }
  },
  methods: {
    unlockAudio() {
      const audioFile = require("@/assets/sounds/tada.mp3");
      const sound = new Audio(audioFile);

      sound.autoplay = true;
      sound.volume = 0;
      sound.play();
      sound.pause();
      sound.currentTime = 0;

      this.$store.commit("USER/AUDIO_SET", sound);

      document.body.removeEventListener("click", this.unlockAudio);
      document.body.removeEventListener("touchstart", this.unlockAudio);
    },
    async initialize() {
      this.$store.commit("USER/TIMER_SET", 0);

      try {
        let lsDate = localStorage.getItem("blockables-date");
        let lsTimed = localStorage.getItem("blockables-timed");

        if (!((lsDate == this.date || this.exampleStepper) && lsTimed)) {
          this.$store.commit("USER/ELIMINATE_LOCAL_STORAGE");
          localStorage.setItem("blockables-date", this.date);
        }
      } catch {
        // localstorage inaccessible, ignore it
      }

      this.$nextTick(() => {
        this.$store.commit("USER/TIMER_START");

        if (this.example && this.exampleStepper < 2) {
          this.$store.commit("PUZZLE/ARROW_CLASS_SET", "clockwise");
        }

        let r = this.firstSquare.r;
        let c = this.firstSquare.c;
        document.getElementById(`r${r}c${c}`).focus();
      });
    },
  },
};
</script>

<style scoped></style>
