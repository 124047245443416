import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import setters from "./setters.js";

export default {
  namespaced: true,

  state: {
    arrowClass: "hidden",
    assignedFocus: "grid",
    checks: [],
    clues: null,
    dimension: 5,
    direction: null,
    gridColors: null,
    metadata: {
      author: {
        link: "",
        name: "",
      },
      category: "",
      editor: "",
      puzzleId: "",
      puzzleNumber: 0,
    },
    numberPlacements: null,
    phrase: "",
    playerGrid: [],
    scratches: {},
    selected: {
      r: 0,
      c: 0,
    },
    winGrid: null,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
    ...setters,
  },
};
