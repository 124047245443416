var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{style:({
    color: _vm.dark ? 'white' : 'black !important',
    'text-align': 'center',
    'text-wrap': 'nowrap',
    'margin-bottom': 0,
    'font-size': '0.875rem',
  })},[_vm._v(" "+_vm._s(_vm.unwoundSpiral)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }