<template>
  <v-container class="home" style="margin: 100px">
    <v-row>
      <v-col cols="4">
        <table
          class="bb-table"
          style="position: absolute; z-index: 1000; pointer-events: none"
        >
          <tr v-for="(i, r) in 5" :key="r">
            <td v-for="(j, c) in 5" :key="c" class="bb-number-cell">
              <strong
                :style="{
                  position: 'absolute',
                  color: renderNumber(r, c) ? 'black' : 'rgba(0, 0, 0, 0.5)',
                }"
              >
                {{ renderNumber(r, c) || previewNumber(r, c) }}
              </strong>
              <v-icon
                v-if="r == '2' && c == '2' && step > 1"
                :class="'bb-arrow bb-arrow-' + direction"
              >
                {{ icons[direction] }}
              </v-icon>
            </td>
          </tr>
        </table>
        <table class="bb-table" @mouseleave="mousePressed = false">
          <tr v-for="(i, r) in 5" :key="r">
            <td
              v-for="(j, c) in 5"
              :id="`r${r}c${c}`"
              :key="c"
              class="bb-cell unselectable"
              :style="{
                backgroundColor: gridColors[r][c],
              }"
              @mouseenter="handleMouseover(r, c)"
              @mousedown="handleMousedown(r, c)"
              @mouseup="mousePressed = false"
            >
              {{ step > 1 ? grid[r][c] : "" }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="8">
        <v-component :is="'Step' + this.step" mode="creator"></v-component>
        <v-btn class="mt-3" block light @click="customFunction">
          Custom function
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Step1 from "@/components/creator/Step1.vue";
import Step2 from "@/components/creator/Step2.vue";
import Step3 from "@/components/creator/Step3.vue";
import Step4 from "@/components/creator/Step4.vue";
import {
  mdiArrowLeftBold,
  mdiArrowUpBold,
  mdiArrowRightBold,
  mdiArrowDownBold,
} from "@mdi/js";

export default {
  name: "Creator",
  components: { Step1, Step2, Step3, Step4 },
  data: () => ({
    icons: {
      down: mdiArrowDownBold,
      left: mdiArrowLeftBold,
      right: mdiArrowRightBold,
      up: mdiArrowUpBold,
    },
    mousePressed: false,
    previewPlacements: {},
    selected: {
      r: null,
      c: null,
    },
  }),
  computed: {
    direction() {
      return this.$store.state.CREATOR.direction;
    },
    grid() {
      return this.$store.state.CREATOR.grid;
    },
    gridColors() {
      return this.$store.state.CREATOR.gridColors;
    },
    numberPlacements() {
      return this.$store.state.CREATOR.numberPlacements;
    },
    step() {
      return this.$store.state.CREATOR.step;
    },
    targeting() {
      return this.$store.state.CREATOR.targeting;
    },
  },
  methods: {
    async customFunction() {
      console.log("custom");
    },
    handleMouseover(r, c) {
      if (this.step == 2 && this.mousePressed) {
        this.$store.commit("CREATOR/COLOR_CELL", { r: r, c: c });
      }
      if (this.step == 3) {
        if (this.targeting.color == this.gridColors[r][c]) {
          this.$set(this.previewPlacements, this.targeting.ordinal + 1, [r, c]);
        } else {
          this.$delete(this.previewPlacements, this.targeting.ordinal + 1);
        }
      }
    },
    handleMousedown(r, c) {
      this.mousePressed = true;
      if (this.step == 2) {
        this.$store.commit("CREATOR/COLOR_CELL", { r: r, c: c });
      }
      if (this.step == 3 && this.targeting.color == this.gridColors[r][c]) {
        this.$store.commit("CREATOR/NUMBER_PLACEMENT_SET", { r: r, c: c });
        this.$store.commit("CREATOR/FIRST_LETTER_SET", {
          ordinal: this.targeting.ordinal + 1,
          letter: this.grid[r][c],
        });
        this.$store.commit("CREATOR/TARGETING_SET", {
          color: null,
          ordinal: null,
        });
      }
    },
    previewNumber(r, c) {
      for (const k in this.previewPlacements) {
        let v = this.previewPlacements[k];
        if (v[0] == r && v[1] == c) {
          return k;
        }
      }
      return null;
    },
    renderNumber(r, c) {
      for (const k in this.numberPlacements) {
        let v = this.numberPlacements[k];
        if (v[0] == r && v[1] == c) {
          return k;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
.bb-table {
  border-collapse: collapse;
  position: absolute;
  width: 500px;
}

.bb-cell {
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-transform: uppercase;
  border: 2px solid black;
  color: black;
  padding: 5px;
  font-size: 50px;
  text-align: center;
  caret-color: transparent;
  cursor: pointer;
}

.bb-cell:focus {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.bb-number-cell {
  background-color: rgba(0, 0, 0, 0);
  width: 100px;
  height: 100px;
  vertical-align: text-top;
  border: 2px solid black;
  color: black;
  padding: 5px;
  font-size: 20px;
}

.bb-arrow {
  font-size: 50px !important;
  position: absolute !important;
  color: black !important;
}

.bb-arrow-up {
  margin-left: 20px;
  margin-top: -37.5px;
}

.bb-arrow-down {
  margin-left: 20px;
  margin-top: 74.5px;
}

.bb-arrow-left {
  margin-left: -35px;
  margin-top: 17.5px;
}

.bb-arrow-right {
  margin-left: 75px;
  margin-top: 17.5px;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
</style>
