<template>
  <v-btn block :color="$colors.green" @click="handleButton">
    {{ dates[exampleStepper].message }}
  </v-btn>
</template>

<script>
export default {
  computed: {
    dates() {
      return [
        {
          date: "03-03-0003",
          message: "Try a harder 3 by 3?",
        },
        {
          date: "03-03-3003",
          message: "Try a 5 by 5?",
        },
        {
          date: "05-05-0005",
          message: "Time for the real thing!",
        },
      ];
    },
    dimension() {
      return this.$store.state.PUZZLE.dimension;
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
  },
  methods: {
    async handleButton() {
      this.$analytics.logEvent("tutorial_step_progressed", {
        step: this.exampleStepper,
      });
      this.$store.commit("USER/CLEAR_LOCAL_STORAGE");
      this.$store.commit("USER/REINITIALIZE");
      this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
      this.$store.commit("USER/EXAMPLE_WIN_DIALOG_SET", false);
      this.$store.commit("USER/WIN_DIALOG_SET", false);
      this.$store.commit("USER/TIMER_START");
      if (this.exampleStepper < 2) {
        let nextStep = this.dates[this.exampleStepper + 1].date;
        this.$store.commit("USER/INCREMENT_EXAMPLE_STEPPER");
        this.$store.commit("USER/DATE_SET", nextStep);
        try {
          await this.$store.dispatch("PUZZLE/FETCH", {
            ark: this.$ark.active,
            date: this.dates[this.exampleStepper].date,
          });
          for (let r = 0; r < this.dimension; r++) {
            for (let c = 0; c < this.dimension; c++) {
              document.getElementById(`r${r}c${c}`).innerText = "";
            }
          }
        } catch (e) {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: true,
            type: "bad",
            text: "002 Blockables was unable to contact the server. Please check your connection or try again later.",
          });
        }
        return;
      }
      this.$store.commit("USER/GAME_START", false);
      this.$store.commit("USER/EXAMPLE_SET", false);
      this.$store.commit("USER/DATE_SET", this.today);
      this.$store.commit("USER/RESET_EXAMPLE_STEPPER");
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
