<template>
  <Puzzle :date="today" />
</template>

<script>
import Puzzle from "@/components/Puzzle.vue";

export default {
  name: "Home",
  components: {
    Puzzle,
  },
  computed: {
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
  },
};
</script>

<style scoped></style>
