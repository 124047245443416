export default {
  ACCOUNT_DIALOG_SET(state, a) {
    state.dialogs.account = a;
  },
  AUDIO_SET(state, a) {
    state.audio = a;
  },
  EXAMPLE_SET(state, e) {
    state.example = e;
  },
  EXAMPLE_WIN_DIALOG_SET(state, e) {
    state.dialogs.exampleWin = e;
  },
  MISSED_PUZZLES_SET(state, mp) {
    state.missedPuzzles = mp;
  },
  MOBILE_KEYPRESS_SET(state, mk) {
    state.mobileKeypress = mk;
  },
  PLAYING_SET(state, v) {
    state.playing = v;
  },
  PUZZLE_DIALOG_SET(state, active) {
    state.dialogs.revealPuzzle = active;
  },
  SETTINGS_SET(state, settings) {
    try {
      localStorage.setItem("blockables-settings", JSON.stringify(settings));
    } catch {
      // localstorage inaccessible, ignore it
    }
    state.settings = { ...settings };
    let counter = 0;
    let pendoInterval = setInterval(() => {
      if ("pendo" in window && "getSerializedMetadata" in window.pendo) {
        const visitor = window.pendo.getSerializedMetadata().visitor;
        window.pendo.updateOptions({
          visitor: {
            ...visitor,
            ...settings,
          },
        });
        clearInterval(pendoInterval);
      }
      if (counter > 20) {
        clearInterval(pendoInterval);
      }
      counter++;
    }, 250);
  },
  STATS_SET(state, stats) {
    state.stats = stats;
  },
  // TIMED_SET(state, timed) {
  //   state.timed = timed;
  // },
  TIMER_SET(state, time) {
    state.timer = time;
  },
  SETTINGS_DIALOG_SET(state, active) {
    state.dialogs.settings = active;
  },
  WIN_DIALOG_SET(state, active) {
    state.dialogs.win = active;
  },

  // Global
  INSTRUCTIONS_DIALOG_SET(state, v) {
    state.dialogs.instructions = v;
  },
  DATE_SET(state, d) {
    state.date = d;
  },
  SNACKBAR_SET(state, payload) {
    state.snackbars[payload.type].active = payload.active;
    state.snackbars[payload.type].text = payload.text;
  },
};
