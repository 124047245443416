<template>
  <v-dialog :value="active" width="325" :dark="dark" @click:outside="close">
    <v-card :dark="dark">
      <v-card-title>Select a puzzle!</v-card-title>
      <v-card-subtitle v-if="$ark.active" class="pb-1">
        {{ wins.length }} / {{ todayOrdinal }} puzzles completed!
      </v-card-subtitle>
      <v-card-subtitle
        v-else
        class="pt-1 pb-1"
        :style="{
          color: dark ? 'white' : 'black !important',
        }"
      >
        <strong>
          {{ completedBlockables }} / {{ todayOrdinal }}
          Blockables
        </strong>
        completed!
        <br />
        {{ completionBreakdown }}
      </v-card-subtitle>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" class="pb-0">
            <v-date-picker
              ref="datepicker"
              v-model="picker"
              style="overflow-y: hidden"
              :color="$colors.blue"
              :dark="dark"
              full-width
              :min="debutDate"
              :max="today"
              @click:month="updateMonth($event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-0">
        <!-- <v-col cols="6" class="d-flex justify-start pt-0">
          <v-btn text :dark="dark" @click="close">Close</v-btn>
        </v-col> -->
        <v-col cols="12" class="d-flex justify-center pt-0">
          <v-btn
            block
            :color="$colors.blue"
            :dark="dark"
            :disabled="!picker"
            @click="play(picker)"
          >
            Play {{ humanReadable(picker) }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiChevronLeft, mdiChevronRight } from "@mdi/js";

const OFFSET_FACTOR = 25;

export default {
  props: ["pppActive"],
  data: () => ({
    active: false,
    allPuzzles: [],
    highestPage: 0,
    icons: {
      check: mdiCheck,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
    },
    offset: 0,
    onlyIncomplete: true,
    loading: false,
    page: 0,
    picker: null,
    // pulledOffsets: [],
  }),
  computed: {
    allPages() {
      return Math.ceil(this.todayOrdinal / OFFSET_FACTOR);
    },
    completedBlockables() {
      if (this.$ark.active) return 0;
      return this.$store.state.USER.stats.length;
    },
    completionBreakdown() {
      let counts = {
        crown: 0,
        sax: 0,
        tada: 0,
      };
      if (this.$ark.active) return counts;
      this.$store.state.USER.stats.forEach(s => {
        counts[s.symbol]++;
      });
      let cb = "";
      if (counts.crown) {
        cb += `${counts["crown"]} 👑 | `;
      }
      if (counts.sax) {
        cb += `${counts["sax"]} 🎷 | `;
      }
      if (counts.tada) {
        cb += `${counts["tada"]} 🎉`;
      }
      if ((counts.crown || counts.sax) && !counts.tada) {
        cb = cb.slice(0, -2);
      }
      return cb;
    },
    currentMonth() {
      return parseInt(this.today.slice(5, 7));
    },
    currentYear() {
      return parseInt(this.today.slice(0, 4));
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    debutDate() {
      return process.env.VUE_APP_DEBUT_DATE;
    },
    emojiMap() {
      return {
        crown: "👑",
        sax: "🎷",
        tada: "🎉",
      };
    },
    paginatedPuzzles() {
      let pp = this.allPuzzles.slice(
        this.page * OFFSET_FACTOR,
        (this.page + 1) * OFFSET_FACTOR
      );
      if (this.onlyIncomplete) {
        pp = pp.filter(puz => !this.puzzleWon(puz.ordinal));
      }
      return pp;
    },
    puzzleNumber() {
      return this.$store.state.PUZZLE.metadata.puzzleNumber;
    },
    stats() {
      return this.$store.state.USER.stats;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
    todayOrdinal() {
      return this.getOrdinal(this.today);
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
    winByGrid() {
      return this.$store.getters["PUZZLE/WIN_BY_GRID"];
    },
    wins() {
      let wins = localStorage.getItem("blockables-ark-wins");
      return JSON.parse(wins);
    },
  },
  watch: {
    async active() {
      this.picker = null;
      if (this.active) {
        await this.pullPuzzles();
        await this.markCompletes();
      }
    },
    async offset() {
      await this.pullPuzzles();
      await this.markCompletes();
    },
    onlyIncomplete() {
      this.allPuzzles = [];
      this.highestPage = 0;
      this.page = 0;
      this.getPuzzles(this.todayOrdinal, this.page);
      this.scrollToTop();
    },
    page() {
      if (this.page > this.highestPage) {
        this.highestPage = this.page;
        this.getPuzzles(this.todayOrdinal, this.page);
        this.scrollToTop();
      }
    },
    async pppActive() {
      if (this.pppActive) {
        // this.getPuzzles(this.todayOrdinal, 0);
        this.active = true;
        await this.addEventListenersToNavigation();
      } else {
        await this.removeEventListenersToNavigation();
        // this.pulledOffsets = [];
      }
    },
  },
  methods: {
    async addEventListenersToNavigation() {
      await this.$nextTick();
      const prevBtn = this.$refs.datepicker.$el.querySelector(
        '.v-btn[aria-label="Previous month"]'
      );
      prevBtn.addEventListener("click", this.decrementOffset);

      const nextBtn = this.$refs.datepicker.$el.querySelector(
        '.v-btn[aria-label="Next month"]'
      );
      nextBtn.addEventListener("click", this.incrementOffset);
    },
    async removeEventListenersToNavigation() {
      await this.$nextTick();
      const prevBtn = this.$refs.datepicker.$el.querySelector(
        '.v-btn[aria-label="Previous month"]'
      );
      prevBtn.removeEventListener("click", this.decrementOffset);

      const nextBtn = this.$refs.datepicker.$el.querySelector(
        '.v-btn[aria-label="Next month"]'
      );
      nextBtn.removeEventListener("click", this.incrementOffset);
    },
    close() {
      this.active = false;
      this.allPuzzles = [];
      this.$emit("modalClose");
    },
    decrementOffset() {
      this.offset -= 1;
    },
    getDaysInMonth(month, year) {
      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date).toISOString().split("T")[0]);
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
    getMonthAgo(months, currentMonth, offset) {
      // Get the index of the current month
      let currentIndex = currentMonth - 1;

      // Calculate the new index considering the offset
      // Add months.length to ensure positive index when going backward
      let newIndex = (currentIndex + offset + months.length) % months.length;

      // Return the month at the new index
      return months[newIndex];
    },
    getOrdinal(d) {
      let o = null;
      try {
        let then = this.debutDate.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = d.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((now - then) / (1000 * 60 * 60 * 24)) + 1;
      } catch {
        o = null;
      }
      return o;
    },
    async getPuzzles(ordinal, offset) {
      this.loading = true;
      let puzzleData = null;
      if (this.$ark.active) {
        puzzleData = await this.$fetch(
          "rpc/get_arkadium_puzzles_for_month",
          "POST",
          {
            month: 0,
            year: 2024,
          }
        );
      } else if (!this.user) {
        const { data, error } = await this.$db.rpc(
          "get_paginated_anonymous_main_site_previous_puzzles",
          {
            lim: OFFSET_FACTOR,
            ordinal: ordinal,
            off: offset * OFFSET_FACTOR,
          }
        );
        if (!error) {
          puzzleData = data;
        }
      } else {
        const { data, error } = await this.$db.rpc(
          "get_paginated_main_site_previous_puzzles",
          {
            lim: OFFSET_FACTOR,
            ordinal: ordinal,
            off: offset * OFFSET_FACTOR,
            user_email: this.user.email,
            only_incomplete: this.onlyIncomplete,
          }
        );
        if (!error) {
          puzzleData = data;
        }
      }
      this.allPuzzles.push(...puzzleData);
      this.loading = false;
    },
    getYearAndMonth(offset) {
      // Adjust the month by subtracting the offset
      let targetMonth = this.currentMonth - offset;

      // Calculate the number of years to subtract
      let yearAdjustment = Math.floor((targetMonth - 1) / 12);

      // Calculate the correct year
      let targetYear = this.currentYear + yearAdjustment;

      // Adjust the month to be within 1 to 12
      targetMonth = ((targetMonth - 1) % 12) + 1;
      if (targetMonth <= 0) {
        targetMonth += 12;
      }

      return {
        year: targetYear,
        month: targetMonth,
      };
    },
    humanReadable(d) {
      if (!d) {
        d = new Date(
          new Date(this.today).toLocaleString("en-US", {
            timeZone: "GMT",
          })
        );
      } else {
        d = new Date(
          new Date(d).toLocaleString("en-US", {
            timeZone: "GMT",
          })
        );
      }

      let dd = d.getDate();

      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][d.getMonth()];

      return `${month} ${dd}${this.nth(dd)}, ${d.getFullYear()}`;
    },
    incrementOffset() {
      this.offset += 1;
    },
    async markCompletes() {
      await this.$nextTick();
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let monthSelection = this.$refs.datepicker.$el.querySelector(
        ".v-date-picker-header__value"
      );
      monthSelection = monthSelection.innerText.split(" ");
      let calendarMonth = monthSelection[0];
      let calendarYear = monthSelection[1];
      // if direct-to-month
      if (monthSelection.length == 1) {
        calendarMonth = this.getMonthAgo(
          months,
          this.currentMonth,
          this.offset
        );
        calendarYear = monthSelection[0];
      }
      // if switching months
      if (monthSelection.length > 2) {
        calendarMonth = monthSelection[1].split("\n")[1];
        calendarYear = monthSelection[2];
      }
      let monthIndex = months.indexOf(calendarMonth) + 1;

      let cal = Array.from(
        this.$refs.datepicker.$el.querySelectorAll(".v-date-picker-table tr")
      );
      let lastSmtwtfsIndex = 0;
      for (let i = 0; i < cal.length; i++) {
        if (cal[i].textContent == "SMTWTFS") {
          lastSmtwtfsIndex = i;
        }
      }
      cal = cal.slice(lastSmtwtfsIndex + 1);

      let wins = null;
      // Add ids and ordinals
      if (this.$ark.active) {
        wins = localStorage.getItem("blockables-ark-wins");
        wins = JSON.parse(wins);
      }

      cal.forEach(tr => {
        Array.from(tr.children).forEach(td => {
          if (!td.textContent) return;
          td.id = `${calendarYear}-${
            monthIndex < 10 ? "0" + monthIndex : monthIndex
          }-${
            parseInt(td.textContent) < 10
              ? "0" + td.textContent
              : td.textContent
          }`;
          let validPuzzle = this.allPuzzles.find(p => p.date == td.id);
          if (typeof validPuzzle === "undefined") return;
          if (this.$ark.active) {
            td.setAttribute("ordinal", validPuzzle.ordinal);
            if (!wins.includes(validPuzzle.ordinal)) return;
            td.querySelector("div").innerHTML =
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path></svg>';
            td.querySelector("button").classList.add("completed");
          } else {
            if (validPuzzle.symbol) {
              let div = td.querySelector("div");
              div.innerText = this.emojiMap[validPuzzle.symbol];
              div.style.fontSize = "18px";
              div.style.marginBottom = "4px";
            }
          }
        });
      });
      this.removeEventListenersToNavigation();
      this.addEventListenersToNavigation();
    },
    nth(d) {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    play(d) {
      this.$analytics.logEvent("play_past_puzzle");
      this.$store.commit("USER/REINITIALIZE");
      this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
      this.$store.commit("USER/WIN_DIALOG_SET", false);
      this.$store.commit("USER/TIMER_START");
      this.$store.commit("USER/EXAMPLE_SET", false);
      this.$router.push("/puzzles/" + d);
      this.close();
      this.triggerMidroll();
    },
    async pullPuzzles() {
      let puzzleData = null;
      let yearAndMonth = this.getYearAndMonth(-this.offset);
      let year = yearAndMonth["year"];
      let month = yearAndMonth["month"];

      if (this.$ark.active) {
        puzzleData = await this.$fetch(
          "rpc/get_arkadium_puzzles_for_month",
          "POST",
          {
            month: month,
            year: year,
          }
        );
      } else {
        puzzleData = [];
        this.stats.forEach(s => {
          let sameYear = parseInt(s.date.substring(0, 4)) == parseInt(year);
          let sameMonth = parseInt(s.date.substring(5, 7)) == parseInt(month);
          if (sameYear && sameMonth) {
            puzzleData.push({
              date: s.date,
              ordinal: s.ordinal,
              symbol: s.symbol,
            });
          }
        });
      }

      this.allPuzzles.push(...puzzleData);
    },
    puzzleWon(ordinal) {
      try {
        let wins = localStorage.getItem("blockables-ark-wins");
        if (!wins) return false;
        return JSON.parse(wins).includes(ordinal);
      } catch {
        return false;
      }
    },
    scrollToTop() {
      let scr = document.getElementById("custom-scroll");
      scr.scrollTop = 0;
    },
    async updateMonth(e) {
      let [selectedYear, selectedMonth] = e.split("-").map(Number);
      let selectedMonths = selectedYear * 12 + selectedMonth;
      let currentMonths = this.currentYear * 12 + this.currentMonth;
      this.offset = selectedMonths - currentMonths;
    },
    triggerMidroll() {
      if (this.won()) {
        try {
          let wins = localStorage.getItem("blockables-ark-wins");
          if (!wins) {
            wins = [this.puzzleNumber];
          } else {
            wins = JSON.parse(wins);
            wins.push(this.puzzleNumber);
          }
          localStorage.setItem("blockables-ark-wins", JSON.stringify(wins));
          this.$store.commit("USER/ELIMINATE_LOCAL_STORAGE");
        } catch {
          // localstorage inaccessible, ignore it
        }
        // this.navToHome();
        this.$ark.api.handleMidrollRequest();
      }
    },
    updatePage(amount) {
      let temp = this.page + amount;
      if (temp < 0) {
        this.page = 0;
        return;
      }
      if (temp >= this.allPages) {
        this.page = this.allPages - 1;
        return;
      }
      this.page = temp;
    },
    won() {
      return this.winByGrid;
    },
  },
};
</script>

<style>
.completed {
  color: var(--blue) !important;
  pointer-events: none;
  cursor: default;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.v-date-picker-title__date {
  font-size: 16px;
}

.v-picker__title {
  padding: 12px 16px;
  display: none;
}

.v-picker__body {
  flex: 0.15 0 auto;
}

.v-date-picker-title__year {
  display: none;
}
</style>
