<template>
  <v-dialog :dark="dark" :value="active" width="500">
    <v-card style="overflow: hidden">
      <v-row>
        <v-spacer />
        <v-col cols="2" class="pb-0 pt-4">
          <v-img
            v-if="$ark.active"
            height="100"
            alt="Blockables logo"
            contain
            :src="require('@/assets/blockables-arkadium.png')"
            transition="scale-transition"
          />
          <v-img
            v-else
            height="100"
            alt="Blockables logo"
            contain
            :src="require('@/assets/blockables-square.png')"
            transition="scale-transition"
          />
        </v-col>
        <v-spacer />
      </v-row>
      <v-row style="height: 150px">
        <v-spacer />
        <v-col
          cols="8"
          class="pt-0 justify-center align-center d-flex text-center"
        >
          <p style="font-size: 24px">
            Are you sure you want to reveal the entire puzzle?
          </p>
        </v-col>
        <v-spacer />
      </v-row>

      <v-divider />

      <v-card-actions>
        <v-col cols="6">
          <v-btn
            :color="dark ? 'white' : $colors.blue"
            text
            block
            @click="reveal"
          >
            Reveal
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            :color="dark ? 'white' : $colors.blue"
            text
            block
            @click="close"
          >
            Close
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    active() {
      return this.$store.state.USER.dialogs.revealPuzzle;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
  },
  methods: {
    close() {
      this.$store.commit("USER/PUZZLE_DIALOG_SET", 0);
    },
    reveal() {
      if (this.$ark.active) {
        this.$ark.api.handleEventChange();
      }
      this.$store.commit("USER/PUZZLE_CONFIRM_REVEAL", 1);
    },
  },
};
</script>

<style></style>
