import { createClient } from "@supabase/supabase-js";

// Create a single supabase client for interacting with your database
const url = "https://cterwiozjbjdeoazbzdd.supabase.co";
const key = process.env.VUE_APP_SUPABASE_KEY;
const supabase = createClient(url, key);
export const db = supabase;

export const sbfetch = async function (queryString, method, body = null) {
  if (method == "GET") {
    let f = await fetch(`${url}/rest/v1/${queryString}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${key}`,
        apikey: key,
        "Content-Type": "application/json",
      },
    });
    return await f.json();
  }
  if (method == "POST") {
    let f = await fetch(`${url}/rest/v1/${queryString}`, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${key}`,
        apikey: key,
        "Content-Type": "application/json",
      },
    });
    return await f.json();
  }
};
