export default {
  CLUE_OF_SELECTED: state => {
    if (state.gridColors) {
      return state.clues.filter(
        c => c.color == state.gridColors[state.selected.r][state.selected.c]
      )[0];
    }
    return { ordinal: 0, clue: "", color: "white" };
  },
  WIN_BY_GRID: state => {
    return (
      JSON.stringify(state.playerGrid).toLowerCase() ==
      JSON.stringify(state.winGrid).toLowerCase()
    );
  },
};
