export default {
  async GET_STATS_FROM_DB({ commit }, u) {
    let allPuzzleAccess = await this.$db.from("user_mgmt").select();
    if (!allPuzzleAccess.error) {
      commit("SET_ALL_PUZZLES", true);
    }
    let recordToSave = null;
    try {
      recordToSave = localStorage.getItem("blockables-record");
    } catch {
      recordToSave = null;
    }
    if (recordToSave) {
      let partialRecord = JSON.parse(recordToSave);
      const { error } = await this.$db
        .from("stats")
        .insert({ ...partialRecord, user_email: u.email });
      if (error && error.code == "23505") {
        localStorage.removeItem("blockables-record");
      }
    }
    const { data, error } = await this.$db.rpc("get_stats_order_by_ordinal", {
      user_email: u.email,
    });
    if (!error) {
      commit("STATS_SET", [...data]);
    }

    let resp = await this.$db.rpc("get_past_seven_puzzles", {
      user_email: u.email,
    });
    let gpspd = resp.data;
    let gpspe = resp.error;
    if (!gpspe) {
      commit("MISSED_PUZZLES_SET", [...gpspd]);
    }
  },
  async SIGN_OUT({ commit }) {
    const { error } = await this.$db.auth.signOut();
    if (!error) {
      commit("ELIMINATE_LOCAL_STORAGE");
      commit("SET_ALL_PUZZLES", true);
      commit("USER_CLEAR", null, { root: true });
    }
  },
};
