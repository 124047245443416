import getters from "./getters.js";
import mutations from "./mutations.js";
import setters from "./setters.js";

export default {
  namespaced: true,

  state: {
    // creator
    step: 1,

    grid: [
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
      [null, null, null, null, null],
    ],
    phrase: "",
    direction: "up",
    coilPattern: "clockwise",

    // STEP 2
    color: null,
    gridColors: [
      ["white", "white", "white", "white", "white"],
      ["white", "white", "white", "white", "white"],
      ["white", "white", "white", "white", "white"],
      ["white", "white", "white", "white", "white"],
      ["white", "white", "white", "white", "white"],
    ],

    // STEP 3
    clues: {},
    numberPlacements: {},
    targeting: {
      color: null,
      ordinal: null,
    },
    words: {},
  },
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
    ...setters,
  },
};
