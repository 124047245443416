<template>
  <div
    :class="keyboardClass"
    class="sticky-keyboard"
    style="font-family: 'Roboto', sans-serif !important"
  />
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  computed: {
    assignedFocus() {
      return this.$store.state.PUZZLE.assignedFocus;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    selected() {
      return this.$store.state.PUZZLE.selected;
    },
    winByGrid() {
      return this.$store.getters["PUZZLE/WIN_BY_GRID"];
    },
  },
  watch: {
    dark() {
      this.handleDarkMode();
    },
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      buttonTheme: [],
      layout: {
        show: ["Q W E R T Y U I O P", "A S D F G H J K L", "Z X C V B N M del"],
      },
      layoutName: "show",
      onKeyPress: this.onKeyPress,
      onKeyReleased: this.onKeyReleased,
    });
    this.handleDarkMode();
  },
  methods: {
    handleDarkMode() {
      if (this.dark) {
        this.keyboard.addButtonTheme(
          "Q W E R T Y U I O P A S D F G H J K L Z X C V B N M del",
          "blockables-black-key"
        );
      } else {
        if (this.keyboard) {
          this.keyboard.removeButtonTheme(
            "Q W E R T Y U I O P A S D F G H J K L Z X C V B N M del",
            "blockables-black-key"
          );
        }
      }
    },
    onKeyPress(button) {
      if (this.winByGrid) {
        return;
      }
      this.$store.commit("USER/MOBILE_KEYPRESS_SET", button);
    },
    onKeyReleased() {
      if (this.assignedFocus == "grid") {
        let id = "r" + this.selected.r + "c" + this.selected.c;
        document.getElementById(id).focus();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.blockables-black-key {
  background: rgb(23, 23, 23) !important;
  color: white;
}

.sticky-keyboard {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 4;
}
</style>
