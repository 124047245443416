import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { db, sbfetch } from "./supabaseDb.js";

Vue.prototype.$db = db;
store.$db = db;

Vue.prototype.$colors = {
  blue: "#7C93CB",
  red: "#EE7B8C",
  green: "#A9D594",
  purple: "#B86BAC",
  pink: "#E6B0D1",
  orange: "#F5C146",
  yellow: "#F3F1A0",
  "light-blue": "#A1DBE3",
  "default-text": "#000",
};

Vue.prototype.$ark = { active: false };
if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium") {
  require("../public/arena-api-integration-blockables/arena-api-1.2.3.js");
  require("../public/arena-api-integration-blockables/ArenaHelper.js");

  const ArenaHelper = require("../public/arena-api-integration-blockables/ArenaHelper.js");
  Vue.prototype.$ark = { active: true, api: ArenaHelper.getInstance() };

  Vue.prototype.$db = null;

  Vue.prototype.$fetch = sbfetch;
  store.$fetch = Vue.prototype.$fetch;

  Vue.prototype.$colors = {
    blue: "#5897A9",
    red: "#DE7567",
    green: "#838D5D",
    purple: "#C4B461",
    pink: "#F0D7DA",
    orange: "#EB8D50",
    yellow: "#EFC237",
    "light-blue": "#C6DCE9",
    "default-text": "#FFF",
  };
}

Vue.config.productionTip = false;

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBp4oEzvLxRaeOE5YPvVmLyNaZ2aOyuV7o",
  authDomain: "blockables-e506c.firebaseapp.com",
  projectId: "blockables-e506c",
  storageBucket: "blockables-e506c.appspot.com",
  messagingSenderId: "734321867779",
  appId: "1:734321867779:web:d4a5c7c0c76e7a08f46236",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();

Vue.prototype.$analytics = analytics;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
