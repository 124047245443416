<template>
  <v-card
    :dark="dark"
    flat
    style="background: none; height: 250px"
    v-if="user && missedPuzzles.length"
  >
    <v-card-text id="MAP-custom-scroll">
      <p>As a registered user, you can play puzzles from the past 7 days!</p>
      <v-list v-for="item in missedPuzzles" :key="item.ordinal">
        <v-list-item
          @click="play(item.date)"
          two-line
          :disabled="item.symbol !== null"
        >
          <v-list-item-content>
            <v-list-item-title>
              <strong>Blockable #{{ item.ordinal }}</strong>
            </v-list-item-title>

            <v-list-item-subtitle>
              <div>
                <span>{{ getFullDate(item.date) }}</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon x-large :color="$colors.blue" v-if="item.symbol === null">
              {{ icons.chevronRight }}
            </v-icon>
            <span style="font-size: 1.5rem" v-else>
              {{ emojiMap[item.symbol] }}
            </span>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChevronRight } from "@mdi/js";

export default {
  data: () => ({
    active: false,
    icons: {
      chevronRight: mdiChevronRight,
    },
    missed: [],
  }),
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    emojiMap() {
      return {
        crown: "👑",
        sax: "🎷",
        tada: "🎉",
      };
    },
    missedPuzzles() {
      return this.$store.state.USER.missedPuzzles;
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
  },
  methods: {
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
    play(d) {
      this.$store.commit("USER/ACCOUNT_DIALOG_SET", false);
      this.$router.push("/puzzles/" + d);
    },
  },
};
</script>

<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
#MAP-custom-scroll {
  scrollbar-width: auto;
  scrollbar-color: var(--blue) rgba(0, 0, 0, 0);
  overflow-y: scroll;
}

/* Chrome, Edge, and Safari */
#MAP-custom-scroll::-webkit-scrollbar {
  width: 4px;
}

#MAP-custom-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#MAP-custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 4px;
  border: 0;
}
</style>
