<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" class="d-flex justify-center">
        <v-card width="500" :dark="dark">
          <v-card-title v-if="user">Account</v-card-title>
          <v-card-subtitle v-if="user">{{ user.email }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h1>Privacy Policy</h1>
                <h6>v1</h6>
                <br />
                Your privacy is important to us. To better protect your
                sensitive information, we provide this notice explaining our
                online information practices and how they relate specifically in
                regard of what type or quantity data might be collected from you
                by way of using the website at any given time during browsing
                sessions on either desktop computers, tablets, or mobile
                devices.
                <br />
                <br />
                <h3>Our Commitment</h3>
                <br />
                We collect email addresses and game performance data to catalog
                game completion stat information. We do not run ads on
                https://www.blockables.app. Third party vendors may use cookies
                to serve ads based on a user's prior visits to your website or
                other websites. Google's use of advertising cookies enables it
                and its partners to serve ads to your users based on their visit
                to your sites and/or other sites on the Internet. Users may opt
                out of personalized advertising.

                <br />
                <br />
                <h3>Information We Collect</h3>
                <br />
                We collect the IP address and click patterns of our users for a
                period, and this data is never sold or shared with anyone else.
                We use it to see how people interact online so that we can make
                improvements on what they experience in their user interface
                (UI). Email addresses are stored at the user's consent and
                coordinated with game completion statistics.

                <br />
                <br />
                <h3>Third Party Services</h3>
                <br />
                We collect generic website analytics by way of website browser
                information. Our analytics data collected is anonymous. No ads
                are served on https://www.blockables.app.

                <br />
                <br />
                <h3>Log Data</h3>
                <br />
                We collect certain log data whenever you access our website or
                services. The information we collect may include your IP
                address, device name and version as well as the time you utilize
                our service. We use these and other statistics to help us
                provide improvements to the website.

                <br />
                <br />
                <h3>Cookies</h3>
                <br />
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory. This Service does not use these
                “cookies” explicitly. However, the app/website may use third
                party code and libraries that use “cookies” to collect
                information and improve their services. You have the option to
                either accept or refuse these cookies and know when a cookie is
                being sent to your device. If you choose to refuse our cookies,
                you may not be able to use some portions of this Service.

                <br />
                <br />
                <h3>Contact</h3>
                <br />
                Please get in touch with the Blockables team through the
                <a
                  style="
                    text-decoration: underline;
                    color: var(--blue) !important;
                  "
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdGub4LlocGA-z7FiAEfpc1yHbLOj4nXr9x0wEH1uzwu0Ewbg/viewform"
                  _target="blank"
                >
                  Blockables Feedback Form.
                </a>
                <br />
                <br />
                Submit a bug report, a complaint, a compliment, or just say hey!
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :color="$colors.blue"
                  block
                  class="white--text"
                  @click="signout"
                  v-if="user"
                >
                  Sign Out
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :color="$colors.red"
                      block
                      class="black--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Delete Account
                    </v-btn>
                  </template>

                  <v-card :dark="dark" style="overflow-y: hidden">
                    <v-card-title>Delete your account?</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <p style="font-size: 16px" class="text-center">
                            Are you sure you want to delete your account and all
                            your stats?
                            <br />
                            <br />
                            This action cannot be undone.
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-row>
                        <v-col cols="6">
                          <v-btn
                            :color="$colors.red"
                            block
                            @click="delete_user"
                          >
                            YES
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn block text @click="dialog = false">NO</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
  },
  async mounted() {
    this.$store.commit("USER/ACCOUNT_DIALOG_SET", false);
    this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", false);
    this.$store.commit("USER/SETTINGS_DIALOG_SET", false);
    this.$store.commit("USER/WIN_DIALOG_SET", false);
    if (this.user) {
      this.$store.commit("USER_SET", this.user);
    } else {
      const {
        data: { user },
      } = await this.$db.auth.getUser();
      if (user) {
        this.$store.commit("USER_SET", user);
      }
    }
  },
  methods: {
    async signout() {
      this.$store.dispatch("USER/SIGN_OUT");
      this.$router.push("/");
    },
    async delete_user() {
      await this.$db
        .from("user_mgmt")
        .update({ marked_for_deletion: true })
        .eq("user_email", this.user.email);
      await this.signout();
    },
  },
};
</script>

<style></style>
