export default {
  // Player
  CLEAR_LOCAL_STORAGE() {
    try {
      localStorage.removeItem("blockables-assists");
      localStorage.removeItem("blockables-clues");
      localStorage.removeItem("blockables-grid");
      localStorage.removeItem("blockables-puzzle-revealed");
      localStorage.removeItem("blockables-record");
      localStorage.removeItem("blockables-scratches");
      localStorage.removeItem("blockables-time");
      localStorage.removeItem("blockables-timed");
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  ELIMINATE_LOCAL_STORAGE() {
    try {
      localStorage.removeItem("blockables-assists");
      localStorage.removeItem("blockables-clues");
      // localStorage.removeItem("blockables-date");
      localStorage.removeItem("blockables-grid");
      localStorage.removeItem("blockables-puzzle-revealed");
      localStorage.removeItem("blockables-record");
      localStorage.removeItem("blockables-scratches");
      localStorage.removeItem("blockables-time");
      localStorage.removeItem("blockables-timed");
      localStorage.removeItem("blockables-won-today");
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  INCREMENT_EXAMPLE_STEPPER(state) {
    state.exampleStepper++;
  },
  RESET_EXAMPLE_STEPPER(state) {
    state.exampleStepper = 0;
  },
  COUNTDOWN_START(state) {
    state.interval = setInterval(() => {
      state.countdown++;
    }, 1000);
  },
  GAME_START(state, gs) {
    state.gameStarted = gs;
    state.playing = gs;
  },
  INCREMENT_ASSISTS_COUNTER(state, piece) {
    let type = piece.includes("reveal") ? "reveals" : "checks";
    state.assists[type] += 1;
    state.assists.piece = piece;
    state.assists.crumbs.push(piece);
    try {
      localStorage.setItem("blockables-assists", JSON.stringify(state.assists));
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  COORDS_REVEAL_APPEND(state, coord) {
    let cString = `${coord.r}-${coord.c}`;
    if (state.assists.coordsRevealed.includes(cString)) return;
    state.assists.coordsRevealed.push(cString);
    try {
      localStorage.setItem("blockables-assists", JSON.stringify(state.assists));
    } catch {
      // localstorage inaccessible, ignore it
    }
  },
  INCREMENT_PLAY_AGAIN_COUNTER(state) {
    if (!state.example) {
      state.playAgainCount += 1;
      state.playing = true;
    }
  },
  PLAY_PAST_PUZZLES_SET(state, set) {
    state.playPastPuzzles = set;
  },
  PUZZLE_CONFIRM_REVEAL(state, active) {
    state.confirmedPuzzleReveal = active;
    if (!state.example) {
      try {
        localStorage.setItem("blockables-puzzle-revealed", active);
      } catch {
        // localstorage inaccessible, ignore it
      }
    }
    state.dialogs.revealPuzzle = false;
  },
  REINITIALIZE(state) {
    try {
      if (localStorage.getItem("blockables-assists")) {
        state.assists = JSON.parse(localStorage.getItem("blockables-assists"));
      } else {
        state.assists = {
          checks: 0,
          coordsRevealed: [],
          crumbs: [],
          piece: "letter",
          reveals: 0,
        };
      }
      state.confirmedPuzzleReveal = localStorage.getItem(
        "blockables-puzzle-revealed"
      );
    } catch {
      state.assists = {
        checks: 0,
        coordsRevealed: [],
        crumbs: [],
        piece: "letter",
        reveals: 0,
      };
    }
  },
  SET_ALL_PUZZLES(state, ap) {
    state.allPuzzles = ap;
  },
  TIMER_START(state) {
    clearInterval(state.interval);
    try {
      // eslint-disable-next-line no-unused-vars
      let testLocal = localStorage.getItem("blockables-time", state.timer);
      state.interval = setInterval(() => {
        state.timer++;
        localStorage.setItem("blockables-time", state.timer);
        localStorage.setItem("blockables-timed", !state.settings.untimed);
      }, 1000);
    } catch {
      state.interval = setInterval(() => {
        state.timer++;
      }, 1000);
    }
  },
  TIMER_STOP(state) {
    clearInterval(state.interval);
  },
};
