import actions from "./actions.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import setters from "./setters.js";

export default {
  namespaced: true,

  state: {
    audio: null,
    allPuzzles: true,
    countdown: 0,
    date: null,
    dialogs: {
      account: false,
      exampleWin: false,
      instructions: false,
      revealPuzzle: false,
      settings: false,
      win: false,
    },
    example: false,
    exampleStepper: 0,
    gameStarted: false,
    interval: null,
    missedPuzzles: [],
    mobileKeypress: null,
    playAgainCount: 0,
    playing: false,
    playPastPuzzles: false,
    stats: [],
    assists: {
      checks: 0,
      coordsRevealed: [],
      crumbs: [],
      piece: "letter",
      reveals: 0,
    },
    confirmedPuzzleReveal: false,
    settings: {
      colorless: false,
      dark: true,
      hk: true,
      silent: false,
      untimed: false,
      unwindSpiral: true,
    },
    snackbars: {
      bad: {
        active: false,
        text: "",
      },
      success: {
        active: false,
        text: "",
      },
    },
    timer: 0,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
    ...setters,
  },
};
