<template>
  <v-dialog
    v-if="!$ark.active"
    :value="active"
    width="500"
    :dark="dark"
    persistent
  >
    <v-card style="border-radius: 10px" :color="$colors.blue">
      <v-card-title class="pt-2 pb-1">
        <v-col
          cols="12"
          class="d-flex justify-center align-center px-0 pb-0 black--text"
        >
          🎉 Goodbye for now! 🎉
        </v-col>
      </v-card-title>
      <v-card-text class="pt-0 pb-1 black--text">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-start">
              <span>
                <strong>
                  On April 17, 2024 (Puzzle #500), I will stop maintaining
                  Blockables. The site will go down on November 5th, deleting
                  your data if you have an account.
                </strong>
                <br />
                <br />
                The entire backlog is now available to play under "Play Past
                Puzzles".
                <br />
                <br />
                I don't think this is the final end of Blockables. I think it's
                just a hiatus.
                <br />
                <br />
                Feel free to email me if you want to stay in touch.
                <br />
                <br />
                Keep puzzling! -- Gus 👑🎷🎉
                <br />
                <a
                  href="mailto:awvieweg@gmail.com"
                  style="
                    text-decoration: underline;
                    font-weight: bold;
                    color: black !important;
                  "
                >
                  awvieweg@gmail.com
                </a>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                hide-details
                class="black--text black-label"
                color="black"
                v-model="dontshow"
                label="Don't show again (I have Gus's email address if I want it)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <v-btn color="black" text block @click="close">Close</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    active: false,
    dontshow: false,
  }),
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
  },
  mounted() {
    let closureNotice = Boolean(
      localStorage.getItem("blockables-closure-notice")
    );
    if (!closureNotice) {
      this.active = true;
    }
  },
  methods: {
    close() {
      if (this.dontshow) {
        localStorage.setItem("blockables-closure-notice", this.dontshow);
      }
      this.active = false;
    },
  },
};
</script>

<style scoped>
.black-label >>> label,
.black-label >>> span {
  color: rgba(0, 0, 0) !important;
}
</style>