<template>
  <Puzzle v-if="puzzleAccess" :date="$route.params.d" />
  <v-container v-else>
    <v-row>
      <v-col class="d-flex justify-center" cols="12">
        <h1
          :style="{
            color: dark ? 'white' : 'black !important',
          }"
          class="text-center"
        >
          Nice try!
        </h1>
      </v-col>
      <v-col class="d-flex justify-center" cols="12">
        <p
          :style="{
            color: dark ? 'white' : 'black !important',
          }"
          class="text-center"
        >
          Signed-in users can only access the previous week of puzzles.
        </p>
      </v-col>
    </v-row>
    <v-row justify-center>
      <v-spacer />
      <v-col cols="10" sm="6">
        <v-btn
          block
          :color="$colors.blue"
          class="white--text"
          @click="$router.push('/')"
        >
          Back to today's puzzle
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import Puzzle from "@/components/Puzzle.vue";

export default {
  components: {
    Puzzle,
  },
  computed: {
    allPuzzles() {
      return true;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    date() {
      return this.$store.state.USER.date;
    },
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT === "prod";
    },
    offset() {
      let d = this.$route.params.d;
      let o = null;
      try {
        let then = d.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = this.today.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((then - now) / (1000 * 60 * 60 * 24));
      } catch {
        o = null;
      }
      return o;
    },
    puzzleAccess() {
      if (this.$ark.active) return true;
      if (this.allPuzzles) return true;
      if (this.isProd) {
        return this.user && this.offset >= -7 && this.offset < 0;
      }
      return true;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
    user() {
      if (this.$ark.active) return null;
      try {
        let localStorageUser = JSON.parse(
          localStorage.getItem("sb-cterwiozjbjdeoazbzdd-auth-token")
        );
        if (!localStorageUser) return null;
        localStorageUser =
          "user" in localStorageUser ? localStorageUser.user : null;
        let storeUser = this.$store.state.sb_user;
        return storeUser || localStorageUser || null;
      } catch {
        return null;
      }
    },
  },
  watch: {
    offset: {
      handler() {
        if (!this.isProd) {
          this.loadPuzzle();
          return;
        }
        if (!this.puzzleAccess || this.offset >= 0) {
          this.$router.push("/");
          return;
        }
        this.loadPuzzle();
      },
      immediate: true,
    },
  },
  methods: {
    loadPuzzle() {
      try {
        let lsd = localStorage.getItem("blockables-date");
        if (lsd != this.$route.params.d) {
          this.$store.commit("USER/CLEAR_LOCAL_STORAGE");
          this.$store.commit("USER/REINITIALIZE");
          this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
          this.$store.commit("USER/EXAMPLE_SET", false);
          this.$store.commit("USER/WIN_DIALOG_SET", false);
          this.$store.commit("USER/ACCOUNT_DIALOG_SET", false);
          this.$store.commit("USER/DATE_SET", this.$route.params.d);
        }
      } catch {
        this.$store.commit("USER/CLEAR_LOCAL_STORAGE");
        this.$store.commit("USER/REINITIALIZE");
        this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
        this.$store.commit("USER/EXAMPLE_SET", false);
        this.$store.commit("USER/WIN_DIALOG_SET", false);
        this.$store.commit("USER/ACCOUNT_DIALOG_SET", false);
        this.$store.commit("USER/DATE_SET", this.$route.params.d);
      }
    },
  },
};
</script>

<style scoped></style>
