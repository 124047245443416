<template>
  <div>
    <v-btn
      class="my-6"
      block
      @click="$store.commit('USER/ELIMINATE_LOCAL_STORAGE')"
    >
      Eliminate Local Storage
    </v-btn>
    <v-text-field
      tabindex="-1"
      @change="overrideDate($event)"
      hide-details
      :dark="dark"
      label="Enter a date (YYYY-MM-DD)"
    />
  </div>
</template>

<script>
export default {
  methods: {
    destroyLocalStorage() {
      localStorage.removeItem("blockables-date");
      this.$store.commit("USER/ELIMINATE_LOCAL_STORAGE");
    },
    overrideDate(e) {
      if (this.isProd) return;
      this.$store.commit("USER/DATE_SET", e);
    },
  },
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
  },
};
</script>

<style></style>
