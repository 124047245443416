<template>
  <v-btn
    block
    :color="$colors.blue"
    class="white--text mb-2"
    @click="$store.commit('USER/PLAY_PAST_PUZZLES_SET', true)"
  >
    Play Past Puzzles
  </v-btn>
</template>

<script>
export default {
  computed: {
    allPuzzles() {
      return true;
    },
  },
};
</script>

<style>
</style>